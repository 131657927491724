.warning--unsuported-browser {
    margin-top:-20px;
    margin-bottom: 10px;
    text-align: center;

    >div {
        display: inline-block;
        font-size:1rem;
        background: #fbf9cf;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: .5em 1em;
        text-align: center;

        h2 {
            font-size:1.15em;
            margin:0 1ex 0 0;
            text-shadow: none;
            display:inline-block;
        }

        p {
            font-size:1em;
            margin:0;
            display:inline-block;
        }
    }
}

body.uses-layout {
    .firsthead {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        h1 {
            flex-grow: 1;

            .jTip img {
                vertical-align: middle;
            }
        }
    }
}

body>.flash-card {
    position: fixed;
    top: 1em;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10000;

    >div {
        max-width: 30vw;
        font-size: 1.3em;
        padding: .5em 3em;
        box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.5);
        border-width: 1px;
        border-style: solid;
        border-radius: 10px;
        opacity: 0;
        transition: opacity .25s;
    }

    &[data-type="info"]>div {
        background-color: #fff;
        border-color: #000;
        color: #000;
    }

    &[data-type="success"]>div {
        background-color: #080;
        border-color: #fff;
        color: #fff;
    }

    &[data-type="warning"]>div {
        background-color: #ff0;
        border-color: #000;
        color: #000;
    }

    &[data-type="error"]>div {
        background-color: #900;
        border-color: #fff;
        color: #fff;
    }
}